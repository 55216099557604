import * as Actions from "../actions/tourActions";

const initState = {
  tours: [],
  subscribeInfo: null,
  subscribeError: null
};

const tourReducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.FETCH_TOURS_SUCCESS:
      return {
        ...state,
        tours: action.payload
      };
    case Actions.SUBSCRIBE_TOUR_SUCCESS:
      return {
        ...state,
        subscribeInfo: action.payload
      };
    case Actions.SUBSCRIBE_TOUR_ERROR:
      return {
        ...state,
        subscribeError: action.payload
      };
    default:
      return state;
  }

};

export default tourReducer;
