import PropTypes from "prop-types";
import React from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import ProductGridTwo from "./grid/ProductGridTwo";
import { checkExistsNew } from "src/helpers/helpers";

const TabProduct = ({
  spaceClass,
  category,
  productTabClass,
  strings,
}) => {
  return (
    <div
      className={`product-area ${spaceClass ? spaceClass : ""}`}
    >
      <div className="container">
        <Tab.Container defaultActiveKey="bestSeller">
          <Nav
            variant="pills"
            className={`product-tab-list-2 mb-60 ${productTabClass ? productTabClass : ""
              }`}
          >
            {checkExistsNew() &&
              <Nav.Item>
                <Nav.Link eventKey="newArrival">
                  <h4>Novidades</h4>
                </Nav.Link>
              </Nav.Item>
            }
            <Nav.Item>
              <Nav.Link eventKey="bestSeller">
                <h4>Mais vendidos</h4>
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link eventKey="saleItems">
                <h4>Itens à venda</h4>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="newArrival">
              <div className="row">
                <ProductGridTwo
                  category={category}
                  type="new"
                  limit={4}
                  spaceClass="mb-25"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="bestSeller">
              <div className="row">
                <ProductGridTwo
                  category={category}
                  type="bestSeller"
                  limit={4}
                  spaceClass="mb-25"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="saleItems">
              <div className="row">
                <ProductGridTwo
                  category={category}
                  type="saleItems"
                  limit={4}
                  spaceClass="mb-25"
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <div className="view-more text-center mt-20 toggle-btn6 col-12">
          <Link
            className="loadMore6 uppercase"
            to={"/loja"}
          >
            {strings["view_more_products"]}
          </Link>
        </div>
      </div>
    </div>
  );
};

TabProduct.propTypes = {
  category: PropTypes.string,
  productTabClass: PropTypes.string,
  spaceClass: PropTypes.string,
};

export default multilanguage(TabProduct);
