import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import Swiper from "react-id-swiper";
import sliderData from "../../data/bannerPrincipal.json";
import HeroSliderSingle from "./HeroSliderSingle.js";

const HeroSlider = ({ spaceClass }) => {
  const params = {
    effect: "fade",
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    ),
  };
  return (
    <div
      className={`slider-area ${spaceClass ? spaceClass : ""}`}
    >
      <div className="slider-active nav-style-1">
        <Swiper {...params}>
          {sliderData && _.filter(sliderData, x => x.title.length > 0).map((single, key) => {
            return (
              <HeroSliderSingle
                data={single}
                key={key}
                sliderClass="swiper-slide"
              />
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

HeroSlider.propTypes = {
  spaceClass: PropTypes.string,
};

export default HeroSlider;
