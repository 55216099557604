
import axios from "axios";
export const FETCH_TOURS_SUCCESS = "FETCH_TOURS_SUCCESS";
export const SUBSCRIBE_TOUR_SUCCESS = "SUBSCRIBE_TOUR_SUCCESS";
export const SUBSCRIBE_TOUR_ERROR = "SUBSCRIBE_TOUR_ERROR";

const fetchToursSuccess = tours => ({
  type: FETCH_TOURS_SUCCESS,
  payload: tours
});

export const fetchTours = tours => {
  return dispatch => {
    dispatch(fetchToursSuccess(tours));
  };
};

export const subscribeTour = (form) => {
  return dispatch => {
    axios.post('https://us-central1-cronicasviageiro.cloudfunctions.net/tour/subscribe', form)
      .then(function (response) {
        dispatch({
          type: SUBSCRIBE_TOUR_SUCCESS,
          payload: "SUCCESS"
        });
      })
      .catch(function (error) {
        dispatch({
          type: SUBSCRIBE_TOUR_ERROR,
          payload: "ERROR"
        });
      });
  };
};

export const clearSubscribe = () => {
  return dispatch => {
    Promise.all([
      dispatch({
        type: SUBSCRIBE_TOUR_SUCCESS,
        payload: null
      }),
      dispatch({
        type: SUBSCRIBE_TOUR_ERROR,
        payload: null
      })
    ]);
  }
}