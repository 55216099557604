import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { load, save } from "redux-localstorage-simple";
import thunk from "redux-thunk";
import App from "./App";
import "./assets/scss/style.scss";
import tours from "./data/passeios.json";
import products from "./data/produtos.json";
import { fetchProducts } from "./redux/actions/productActions";
import { fetchTours } from "./redux/actions/tourActions";
import rootReducer from "./redux/reducers/rootReducer";
import * as serviceWorker from "./serviceWorker";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);
store.dispatch(fetchProducts(products));
store.dispatch(fetchTours(tours));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
