import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect } from "react";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { loadLanguages, multilanguage } from "redux-multilanguage";
import ScrollToTop from "./helpers/scroll-top";
import Home from "./pages/Home";
import Maintenance from "./pages/Maintenance";
import { underDevelopment } from "./helpers/helpers";
//const Home = lazy(() => import("./pages/Home"));
const ShopList = lazy(() => import("./pages/ShopList"));
const Product = lazy(() => import("./pages/Product"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Cart = lazy(() => import("./pages/Cart"));
const Checkout = lazy(() => import("./pages/Checkout"));
const NotFound = lazy(() => import("./pages/NotFound"));
//const Maintenancee = lazy(() => import("./pages/Maintenance"));
const Tours = lazy(() => import("./pages/Tours"));
const Tour = lazy(() => import("./pages/Tour"));
const Wishlist = lazy(() => import("./pages/Wishlist"));

const App = (props) => {

  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          pt: require("./translations/portuguese.json"),
          en: require("./translations/english.json"),
        },
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {!underDevelopment() &&
                  <Route
                    exact
                    path={"/"}
                    component={() => <Redirect to="/tours" />}
                  />
                }
                {underDevelopment() &&
                  <Route
                    exact
                    path={"/"}
                    component={() => <Redirect to="/home" />}
                  />
                }
                {underDevelopment() &&
                  <Route
                    path={"/home"}
                    component={Home}
                  />
                }
                {underDevelopment() &&
                  <Route
                    path={"/loja"}
                    component={ShopList}
                  />
                }
                <Route
                  path={"/produto/:slug"}
                  component={Product}
                />
                <Route
                  path={"/tour/:slug"}
                  component={Tour}
                />
                <Route
                  path={"/tours"}
                  component={Tours}
                />
                <Route
                  path={"/sobre"}
                  component={About}
                />
                <Route
                  path={"/contato"}
                  component={Contact}
                />
                <Route
                  path={"/carrinho"}
                  component={Cart}
                />
                <Route
                  path={"/checkout"}
                  component={Checkout}
                />
                <Route
                  path={"/favoritos"}
                  component={Wishlist}
                />
                <Route
                  path={"/not-found"}
                  component={NotFound}
                />
                <Route
                  path={"/manutencao"}
                  component={Maintenance}
                />
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
