import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { multilanguage } from "redux-multilanguage";
import Layout from "../Layout";
import Banner from "../components/banner/Banner";
import BrandLogoSlider from "../components/brandLogo/BrandLogoSlider";
import HeroSlider from "../components/heroSlider/HeroSlider";
import TabProduct from "../components/product/TabProduct";

const Home = ({ strings }) => {
  return (
    <Fragment>
      <MetaTags>
        <title>Crônicas de viageiro</title>
        <meta
          name="description"
          content="Tudo sobre o murat do Crônicas de viageiro você encontra aqui!"
        />
      </MetaTags>
      <Layout
        headerContainerClass="container container-fluid"
        headerPaddingClass="header-padding-2"
      >
        <HeroSlider spaceClass="ml-70 mr-70" />
        <Banner spaceClass="pt-60 pb-65" />
        <TabProduct spaceClass="pb-60" />
        <BrandLogoSlider spaceClass="pb-70 pt-70" />
      </Layout>
    </Fragment>
  );
};

export default multilanguage(Home);
