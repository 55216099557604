import PropTypes from "prop-types";
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { multilanguage } from "redux-multilanguage";

const Maintenancee = ({ location, strings }) => {

  return (
    <Fragment>
      <MetaTags>
        <title>{strings["Em manutenção"]} | Crônicas de viageiro</title>
        <meta
          name="description"
          content="Site em manutenção"
        />
      </MetaTags>
      <div className="error-area pt-40 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 text-center">
              <div className="error">
                <img
                  src={"/assets/img/logo/logo-2-lg.webp"}
                  alt="Crônicas de viageiro"
                  style={{ width: "100%" }} />
                <h2>SITE EM MANUTENÇÃO :/</h2>
                <p>tente novamente mais tarde.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Maintenancee.propTypes = {
  location: PropTypes.object,
};

export default multilanguage(Maintenancee);
