import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceClass, colorClass }) => {
  return (
    <div
      className={`copyright ${spaceClass ? spaceClass : ""} ${colorClass ? colorClass : ""
        } d-flex flex-col`}
    >
      <div className="footer-logo">
        <Link to={"/"}>
          <img alt="object-fit-contain" src={footerLogo} height={112} width={104} />
        </Link>
      </div>
      <p className="whitespace-no-wrap">
        &copy; {new Date().getFullYear()}{" "}
        <a
          href="https://www.youtube.com/c/Cr%C3%B4nicasDeViageiro"
          rel="noopener noreferrer"
          target="_blank"
        >
          Crônicas De Viageiro
        </a>
        <br /> Todos os direitos reservados
      </p>
      <p className="version">v{process.env.REACT_APP_VERSION}</p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
