import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import cartReducer from "./cartReducer";
import compareReducer from "./compareReducer";
import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import tourReducer from "./tourReducer";
import wishlistReducer from "./wishlistReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "pt" }),
  currencyData: currencyReducer,
  productData: productReducer,
  tourData: tourReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
});

export default rootReducer;
