import PropTypes from "prop-types";
import React from "react";

const BrandLogoSingle = ({ data, sliderClassName, spaceClass }) => {
  return (
    <a
      href={data.link}
      target="_blank"
      rel="noopener noreferrer"
      className={`single-brand-logo ${sliderClassName ? sliderClassName : ""} ${spaceClass ? spaceClass : ""
        }`}
    >
      <img src={data.image} alt={data.alt} width={160} height={100} />
    </a>
  );
};

BrandLogoSingle.propTypes = {
  data: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceClass: PropTypes.string,
};

export default BrandLogoSingle;
