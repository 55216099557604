import PropTypes from "prop-types";
import React from "react";
import bannerData from "../../data/cardCategoria.json";
import BannerSingle from "./BannerSingle.js";

const Banner = ({ spaceClass }) => {
  return (
    <div
      className={`banner-area ${spaceClass ? spaceClass : ""}`}
    >
      <div className="container">
        <div className="row">
          {bannerData &&
            bannerData.map((single, key) => {
              return (
                <BannerSingle
                  data={single}
                  key={key}
                  spaceClass="mb-30"
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  spaceClass: PropTypes.string,
};

export default Banner;
