import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const HeroSliderSingle = ({ data, sliderClass, strings }) => {
  return (
    <div
      className={`single-slider-2 slider-height-1 d-flex align-items-center slider-height-res bg-img ${sliderClass ? sliderClass : ""
        }`}
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-7 ml-auto">
            <div className="slider-content-2 slider-animated-1">
              {data.subtitle.length > 0 &&
                <h3 className="animated">{data.subtitle}</h3>
              }
              <h1
                className="animated"
                dangerouslySetInnerHTML={{ __html: data.title }}
              ></h1>
              {data.url.length > 0 &&
                <div className="slider-btn btn-hover">
                  <Link
                    className="animated"
                    to={data.url}
                  >
                    {"Veja mais"}
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroSliderSingle.propTypes = {
  data: PropTypes.object,
  sliderClass: PropTypes.string,
};

export default multilanguage(HeroSliderSingle);
