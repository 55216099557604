import PropTypes from "prop-types";
import React from "react";
import Swiper from "react-id-swiper";
import brandLogoData from "../../data/parceiros.json";
import BrandLogoSingle from "./BrandLogoSingle";

const BrandLogoSlider = ({ spaceClass }) => {
  const settings = {
    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    grabCursor: true,
    breakpoints: {
      // 1024: {
      //   slidesPerView: 5,
      // },
      // 768: {
      //   slidesPerView: 4,
      // },
      640: {
        slidesPerView: 3,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <div
      className={`brand-logo-area ${spaceClass ? spaceClass : ""}`}
    >
      <div className="container">
        <div className="brand-logo-active">
          <Swiper {...settings}>
            {brandLogoData &&
              brandLogoData.map((single, key) => {
                return (
                  <BrandLogoSingle
                    data={single}
                    key={key}
                    sliderClassName="swiper-slide"
                    spaceClass=""
                  />
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

BrandLogoSlider.propTypes = {
  spaceClass: PropTypes.string
};

export default BrandLogoSlider;
