import axios from "axios";
export const SET_CURRENCY = "SET_CURRENCY";

export const setCurrency = (currencyName) => {
  return (dispatch) => {
    axios
      .get(
        `https://api.apilayer.com/currency_data/live?source=BRL&currencies=USD,GBP,EUR,BRL`,
        {
          headers: {
            apikey: "QPtI889OHmoZ9KFl55wFURVk7CQTHx2d",
          },
        }
      )
      .then((response) => {
        let currencyRate = 1;
        Object.keys(response.data.quotes).forEach((item) => {
          if (item.replace("BRL", "") === currencyName) {
            currencyRate = response.data.quotes[item];
          }
        });
        dispatch({
          type: SET_CURRENCY,
          payload: { currencyName, currencyRate },
        });
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
};
