import React from "react";

const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-whatsapp"></i>{" "}
              <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+554936640144&text=Fala murat, vim pelo site.">+55 49 3664-0144</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a target="_blank" rel="noopener noreferrer" href="mailto:alextibola@hotmail.com">alextibola@hotmail.com</a>
            </li>
            <li>
              <i className="fa fa-instagram"></i>{" "}
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/cronicasdeviageiro">@cronicasdeviageiro</a>
            </li>
            <li>
              <i className="fa fa-youtube"></i>{" "}
              <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@CronicasDeViageiro">Crônicas de Viageiro</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileWidgets;
