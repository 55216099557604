import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ imageUrl, logoClass, style }) => {
  return (
    <div className={`${logoClass ? logoClass : ""}`} style={style}>
      <Link to={"/"}>
        <img className="object-fit-contain" alt="Crônicas de viageiro" src={imageUrl} width={110} height={60} />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
  style: PropTypes.object,
};

export default Logo;
